<template>
    <b-navbar
        id="main-menu-navbar"
        class="mobile-menu"
        :class="{ custom: isCustomHAF }"
        toggleable="lg"
    >
        <b-navbar-toggle
            target="nav-collapse-disabled"
            @click="toggleNavbar"
        />
        <b-navbar-brand>
            <router-link
                :to="{ name: 'Home' }"
                aria-label="pescario.ro"
            >
                <img
                    src="/logo.svg"
                    height="28px"
                    alt="pescario.ro"
                >
            </router-link>
        </b-navbar-brand>
        <b-navbar-nav class="menu-right">
            <wishlist-item-navbar />
            <cart-item-navbar />
        </b-navbar-nav>
        <b-navbar-nav class="nav-search nav-drop-item">
            <li class="form-inline">
                <navbar-search />
            </li>
        </b-navbar-nav>
        <b-navbar-nav
            class="nav-fixed"
            :class="{ active: !scrolled }"
        >
            <router-link
                v-slot="{ navigate }"
                :to="{ name: 'Catalog' }"
                custom
            >
                <b-nav-item @click="navigate">
                    {{ $t('header.navbar.catalogs') }}
                </b-nav-item>
            </router-link>
            <router-link
                v-slot="{ navigate }"
                :to="{ name: 'Voucher' }"
                custom
            >
                <b-nav-item @click="navigate">
                    {{ $t('header.navbar.vouchers') }}
                </b-nav-item>
            </router-link>
        </b-navbar-nav>
        <div
            id="nav-collapse"
            class="navbar-collapse collapse"
            is-nav
            :class="{ active: isOpen }"
        >
            <b-navbar-nav class="menu-left">
                <li class="custom-nav">
                    <div
                        class="my-account"
                        @click="toggleMyNav"
                    >
                        <span
                            :class="{ avatar: isLogged }"
                        >
                            <user-icon v-if="!isLogged || avatar == ''" />
                            <div
                                v-else
                                class="profile-image"
                            >
                                <img
                                    :src="avatar"
                                    alt="avatar"
                                >
                            </div>
                        </span>
                    </div>
                    <ul class="mobile-language-switcher">
                        <language-item-navbar />
                    </ul>
                </li>
                <li class="nav-item dropdown-mega-menu">
                    <mobile-mega-menu
                        v-if="isOpen"
                        :my-nav="navbar.myNav"
                        @toggleMyNav="toggleMyNav"
                    />
                </li>
            </b-navbar-nav>
        </div>
        <div
            class="menu-shadow"
            :class="{ active: shadow }"
            @click="toggleNavbar()"
        />
    </b-navbar>
</template>
<script>
    import UserIcon from 'vue-feather-icons/icons/UserIcon';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

    const CartItemNavbar = () => import( /* webpackChunkName: "navbar-cart" */ './CartItemNavbar');
    const LanguageItemNavbar = () => import( /* webpackChunkName: "navbar-language" */ './LanguageItemNavbar');
    const NavbarSearch = () => import( /* webpackChunkName: "navbar-search" */ './NavbarSearch');
    const WishlistItemNavbar = () => import( /* webpackChunkName: "navbar-wishlist" */ './WishlistItemNavbar');
    const MobileMegaMenu = () => import( /* webpackChunkName: "navbar-mega-menu" */ './MobileMegaMenu');

    export default {
        name: 'MobileNavbar',
        components: {
            UserIcon,
            CartItemNavbar,
            WishlistItemNavbar,
            LanguageItemNavbar,
            NavbarSearch,
            MobileMegaMenu
        },
        data() {
            return {
                navbar: {
                    open: false,
                    myNav: false,
                },
                shadow: false,
                scrolled: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isLogged']),
            ...mapState('general', ['isCustomHAF']),
            ...mapState('user', ['generalData', 'avatar']),
            isOpen() {
                return this.navbar.open;
            },
        },

        methods: {
            ...mapMutations('auth', ['setConfirmed', 'setReSent']),
            ...mapActions('auth', ['logout']),
            triggerLogout() {
                this.logout();
                this.setConfirmed(false);
                this.setReSent(false);
            },
            toggleNavbar() {
                if (!this.isOpen) {
                    this.navbar.open = true;
                    document.body.classList.add('no-scrolling');
                    setTimeout(() => {
                        this.shadow = true;
                    }, 300);
                } else {
                    this.navbar.open = false;
                    document.body.classList.remove('no-scrolling');
                    this.shadow = false;
                }
            },
            toggleMyNav() {
                this.navbar.myNav = !this.navbar.myNav;
            },
            handleScroll() {
                if (window.scrollY > 150 && !this.scrolled) {
                    this.scrolled = true;
                } else if (window.scrollY <= 150 && this.scrolled) {
                    this.scrolled = false;
                }
            },
        },
        created() {
            if (this.$screen.breakpoint == 'sm' || this.$screen.breakpoint == 'md') {
                window.addEventListener('scroll', this.handleScroll);
            }
        },
        destroyed() {
            if (this.$screen.breakpoint == 'sm' || this.$screen.breakpoint == 'md') {
                window.removeEventListener('scroll', this.handleScroll);
            }
        },
        watch: {
            isLogged: {
                handler(value) {
                    if(value){
                        this.toggleNavbar();
                        this.toggleMyNav();
                    }
                },
                deep: true,
            },
            '$route.params'() {
                if(this.navbar.open){
                    this.navbar.open = false;
                    this.shadow = false;
                    document.body.classList.remove('no-scrolling');

                }
            },
        },
    };
</script>
